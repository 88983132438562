import React from 'react';
import { graphql } from 'gatsby';
import { Link } from '@chakra-ui/react';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const PrivacyPolicy: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { url, pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground url={url}>
        <PageTitle subtitle="" title="Privacy Policy" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">
            <strong>POLÍTICA DE PRIVACIDADE</strong>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">IDENTIFICAÇÃO</Text2>
          <Text3 as="p">
            <ul>
              <li>
                <strong>Controlador de dados</strong>: Ironhack Group INC
                Sucursal España (doravante, "Ironhack")
              </li>
              <li>
                <strong>IVA </strong>: W4009352H
              </li>
              <li>
                <strong>Escritório registrado:</strong>: C/ CASTELLO, 115, BAJOS
                7 - 28006 MADRID
              </li>
              <li>
                <strong>Responsável pela proteção de dados (DPO):</strong>: Você
                pode contatar nosso encarregado da proteção de dados enviando
                uma carta para o endereço registrado da Ironhack, ao cuidado do
                "encarregado da proteção de dados" ou enviando um e-mail para{' '}
                <a href="mailto:legal@ironahack.com">legal@ironahack.com</a>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INFORMAÇÃO E CONSENTIMENTO</Text2>
          <Text3 as="p">
            Ao aceitar esta Política de Privacidade, o Usuário foi devidamente
            informado e concedeu seu consentimento informado, expresso, livre e
            inequívoco para o processamento pela Ironhack dos dados pessoais
            coletados através dos formulários do website{' '}
            <a href="https://www.ironhack.com/br">www.ironhack.com</a>{' '}
            (doravante, o "Website").
          </Text3>
          <Text3 as="p">
            A Ironhack não será responsável por qualquer processamento de dados
            que seja realizado através de websites que não sejam diretamente
            controlados pela Ironhack.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">OBRIGAÇÃO DE FORNECIMENTO DE DADOS</Text2>
          <Text3 as="p">
            Os dados solicitados nos formulários do site são obrigatórios (a
            menos que especificado de outra forma) e necessários para realizar
            as finalidades indicadas abaixo. Portanto, se tais dados não forem
            fornecidos ou forem fornecidos incorretamente, a Ironhack não poderá
            fornecer nenhum serviço que você possa ter solicitado.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            FINALIDADES DO PROCESSAMENTO DE SEUS DADOS E PERÍODO DE
            ARMAZENAMENTO
          </Text2>
          <Text3 as="p">
            Os dados pessoais fornecidos pelo usuário{' '}
            <strong>através do formulário de contato do site</strong> serão
            processados pela Ironhack para os seguintes fins:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Solucionar consultas, gerenciar sugestões e resolver reclamações
                que o Usuário tenha comunicado à Ironhack. Seus dados serão
                armazenados para este fim durante todo o período de tempo em que
                sua consulta for gerenciada e, mesmo mais tarde, até que
                quaisquer possíveis ações derivadas de tal consulta tenham
                expirado.
              </li>
            </ul>
            Os dados pessoais fornecidos pelo Usuário{' '}
            <strong>
              através do formulário de Solicitação de Boletim Informativo do
              Website
            </strong>{' '}
            serão processados pela Ironhack para a seguinte finalidade:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Processamento de seu pedido para ser informado sobre as últimas
                notícias e eventos da Ironhack. Seus dados serão armazenados até
                que você retire seu consentimento.
              </li>
            </ul>
            Os dados pessoais fornecidos{' '}
            <strong>
              pelo formulário de solicitação dos Cursos da Ironhack no Website
            </strong>{' '}
            serão processados pela Ironhack para os seguintes propósitos:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Avaliar e gerenciar sua inscrição e realizar as ações
                necessárias para a seleção dos estudantes. Seus dados pessoais
                serão armazenados durante todo o período de tempo em que sua
                consulta for gerenciada e, mesmo mais tarde, até que quaisquer
                possíveis ações derivadas de tal consulta tenham expirado.
              </li>
              <li>
                Caso você marque a caixa correspondente, processando seu pedido
                para ser informado sobre as últimas notícias e eventos da
                Ironhack. Seus dados serão armazenados até que você retire seu
                consentimento.
              </li>
            </ul>
            Os dados pessoais fornecidos{' '}
            <strong>
              pelo formulário de solicitação da Ironhack Hiring Partners do site
            </strong>{' '}
            serão processados pela Ironhack para os seguintes fins:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Avaliar e gerenciar a condição de Parceiro de Contratação da
                Ironhack. Seus dados pessoais serão armazenados durante todo o
                período de tempo em que sua consulta for gerenciada e, mesmo
                mais tarde, até que quaisquer possíveis ações derivadas de tal
                consulta tenham expirado.
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DADOS PESSOAIS PROCESSADOS PELA IRONHACK</Text2>
          <Text3 as="p">
            Em conexão com o "formulário de contato" disponível no site, a
            Ironhack processará os seguintes dados:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Dados de identificação: nome, sobrenome, etc.</li>
              <li>
                Dados de contato: endereço de e-mail, número de telefone.{' '}
              </li>
            </ul>
            Em conexão com o formulário "Neswletter Request" disponível no site,
            a Ironhack processará os seguintes dados:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Dados de contato: endereço de e-mail.</li>
            </ul>
            Em conexão com o "Formulário de solicitação de cursos da Ironhack"
            disponível no site, a Ironhack processará os seguintes dados:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Dados de identificação: nome, sobrenome, etc.</li>
              <li>
                Dados de contato: endereço de e-mail, número de telefone, etc.{' '}
              </li>
            </ul>
            Em conexão com o "Formulário de solicitação de Parceiros de
            Contratação de Ironhack" disponível no Website, a Ironhack
            processará os seguintes dados:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Dados de identificação: nome, sobrenome, etc.</li>
              <li>Detalhes de emprego: cargo, etc.</li>
              <li>
                Dados de contato: endereço de e-mail, número de telefone, etc.
              </li>
            </ul>
          </Text3>
          <Text3 as="p">
            No caso do Usuário fornecer dados de terceiros, o Usuário declara
            ter obtido o consentimento de tais terceiros e se compromete a
            informar tais terceiros sobre o conteúdo desta Política de
            Privacidade. O Usuário aceita que a Ironhack isenta-se de qualquer
            responsabilidade a este respeito.
          </Text3>
          <Text3 as="p">
            Não obstante o acima exposto, a Ironhack poderá realizar
            verificações periódicas e adotar as correspondentes medidas de
            diligência, de acordo com as normas de proteção de dados
            pertinentes.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">POLÍTICA DE COOKIES</Text2>
          <Text3 as="p">
            Clique{' '}
            <Link href="https://cdn.ironhack.com/marketing/IH_Coookies_Policy.docx">
              aqui
            </Link>{' '}
            para ver nossa política de cookies
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LEGALIDADE DO PROCESSAMENTO DOS DADOS</Text2>
          <Text3 as="p">
            Seus dados serão processados com base na seguinte base legal:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                O processamento dos dados pessoais do usuário na ocasião da
                resolução de consultas, gerenciamento de sugestões e resolução
                de reclamações é baseado no consentimento fornecido pelo
                usuário.
              </li>
              <li>
                O processamento dos dados pessoais do Usuário na ocasião de ser
                informado sobre as últimas notícias e eventos da Ironhack é
                baseado no consentimento fornecido pelo Usuário.
              </li>
              <li>
                O processamento dos dados pessoais do Usuário na ocasião da
                avaliação e gerenciamento de sua aplicação e da realização das
                ações necessárias para a seleção dos estudantes é baseado na
                relação contratual entre a Ironhack e o Usuário.
              </li>
              <li>
                O processamento dos dados pessoais do usuário na ocasião da
                avaliação e gerenciamento da condição de parceiro de contratação
                da Ironhack é baseado na relação contratual entre a Ironhack e o
                usuário.
              </li>
            </ul>
            Por favor, leve em consideração que você pode retirar seu
            consentimento a qualquer momento e a retirada de seu consentimento
            não afetará a legalidade de qualquer processamento realizado antes
            de tal retirada.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DESTINATÁRIOS DOS DADOS</Text2>
          <Text3 as="p">Seus dados pessoais podem ser comunicados:</Text3>
          <Text3 as="p">
            <ul>
              <li>Às Administrações Públicas, nos casos previstos por lei.</li>
              <li>
                Juízes, Tribunais e Órgãos de Segurança, nos casos previstos por
                lei.
              </li>
              <li>
                Serviços com finalidades de marketing & mgmt. de dados:
                <ul>
                  <li>Mailchimp</li>
                  <li>Pipedrive</li>
                  <li>Google Analytics</li>
                  <li>Hotjar</li>
                  <li>Audiência personalizada do Facebook</li>
                </ul>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">TRANSFERÊNCIAS INTERNACIONAIS DE DADOS</Text2>
          <Text3 as="p">
            As informações pessoais podem ser divulgadas e transferidas para:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Campus Ironhack no México, Estados Unidos e Brasil</li>
              <li>Mailchimp Inc. (E.U.A.)</li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">RESPONSABILIDADE DO USUÁRIO</Text2>
          <Text3 as="p">O Usuário:</Text3>
          <Text3 as="p">
            <ul>
              <li>
                Garante que ele tem mais de 18 anos de idade e que os dados
                fornecidos à Ironhack são verdadeiros, exatos, completos e
                atualizados. Para estes fins, o Usuário é responsável pela
                veracidade de todos os dados fornecidos e manterá as informações
                atualizadas de forma oportuna.
              </li>
              <li>
                Garante que ele informou o conteúdo desta Política de
                Privacidade a quaisquer terceiros cujos dados tenham sido
                fornecidos pelo Usuário, se houver. Garante também que foi
                obtida a autorização do terceiro para o fornecimento de tais
                dados de terceiros à Ironhack para os fins aqui indicados.
              </li>
              <li>
                Será responsável por quaisquer informações falsas ou inexatas
                fornecidas, e será responsável por quaisquer danos diretos ou
                indiretos causados à Ironhack ou a terceiros.
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMUNICAÇÕES COMERCIAIS</Text2>
          <Text3 as="p">
            Uma das finalidades para as quais a Ironhack processa dados pessoais
            fornecidos pelos Usuários é enviar-lhes comunicações eletrônicas com
            informações sobre eventos ou notícias relevantes para os Usuários.
            Em qualquer caso, tal tipo de comunicação será dirigida única e
            exclusivamente aos Usuários que expressaram expressamente seu
            consentimento
          </Text3>
          <Text3 as="p">
            Caso o Usuário deseje deixar de receber comunicações comerciais ou
            promocionais da Ironhack, ele poderá solicitar o cancelamento do
            serviço enviando um e-mail para o seguinte endereço eletrônico:{' '}
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">SEUS DIREITOS</Text2>
          <Text3 as="p">
            O Usuário pode contatar a Ironhack enviando uma comunicação por
            escrito para o endereço Calle Castello, 115B, Local 7, 28006,
            Madrid, com a referência "Proteção de Dados", ou enviando um e-mail
            para o endereço{' '}
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a> anexando
            uma cópia de sua identificação, a qualquer momento e gratuitamente.
            O Usuário poderá, a qualquer momento:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Retirar seu consentimento.</li>
              <li>
                Obter confirmação sobre se os dados pessoais relativos ao
                Usuário estão sendo processados pela Ironhack.
              </li>
              <li>Ter acesso a seus dados pessoais.</li>
              <li>Rectificar dados imprecisos ou incompletos.</li>
              <li>Solicitar o apagamento de seus dados.</li>
              <li>
                Restringir o processamento de dados pessoais sempre que qualquer
                uma das condições das normas de proteção de dados for atendida.
              </li>
              <li>Solicitar a portabilidade de seus dados.</li>
              <li>
                Apresentar uma reclamação relativa à proteção de seus dados
                pessoais à autoridade nacional de supervisão competente (no caso
                da Espanha, a Agência Espanhola de Proteção de Dados).
              </li>
            </ul>
            Estes direitos somente serão exercidos quando os requisitos
            estabelecidos nas normas vigentes forem devidamente atendidos.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">MEDIDAS DE SEGURANÇA</Text2>
          <Text3 as="p">
            A Ironhack processará os dados pessoais sempre de forma
            confidencial, de acordo com qualquer regulamentação pertinente,
            adotando as medidas técnicas e organizacionais necessárias para
            garantir a segurança dos dados e evitar sua alteração, perda,
            processamento ou acesso não autorizado. Para a adoção de tais
            medidas de segurança, será levado em consideração o estado da
            tecnologia, a natureza dos dados processados e os riscos envolvendo
            quaisquer possíveis violações.
          </Text3>
          <Text3 as="p">Última atuação: 10/08/2018</Text3>
          <Text3 as="p">
            <strong>
              Copyright © 2018 Ironhack School S.L. Todos os direitos
              reservados.
            </strong>
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "br" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('br')(PrivacyPolicy);
